@media (max-width: 1440px) {
  .header {
    padding: 26px 15px;
  }
  .homeIntro {
    padding: 10px 15px;
  }
  .submitEmail {
    padding: 42px 15px;

    &__main {
      &_left {
        width: auto;
        padding-right: 0;
        margin-right: 20px;
      }

      &_right {
        width: auto;
        flex: auto;
        justify-content: center;

        form {
          input {
            min-width: auto;
          }
        }
      }
    }
  }
  .HomeBenefits {
    padding: 120px 15px;
  }
  .homeFaq {
    padding: 100px 15px;
  }
  .HomeUpdates {
    padding: 100px 15px;
  }
  .homeInvestors {
    padding: 100px 15px;
  }
  .footer {
    padding: 100px 15px;
  }
}

@media (max-width: 1525px) {
  .header__main_settings .selectLang .values {
    left: -100%;
  }
}
