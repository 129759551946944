.wrapper {

  .userName {
    margin-bottom: 50px;
    width: 100%;
    font-weight: bolder;
    font-size: 32px;
    line-height: 48px;
    color: #35353D;
  }
}

.dashboardMain {
  display: flex;
  row-gap: 50px;
  column-gap: 50px;
  flex-flow: row nowrap;


  .Upcoming {
    background: #FFFFFF;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 34px;
    display: flex;
    width: 70%;

    &__box {
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      h2 {
        width: 100%;
        font-weight: bolder;
        font-size: 12px;
        line-height: 132.5%;
        text-transform: uppercase;
        color: rgba(15, 59, 91, 0.6);
      }

      span {
        font-weight: bolder;
        font-size: 24px;
        line-height: 132.5%;
        color: #0F3B5B;
      }
    }

    &__total {
      display: flex;
      flex-direction: column;
      row-gap: 35px;
      padding: 82px 100px 82px 36px;
      justify-content: flex-end;
    }

    &__payments {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      padding: 50px;
      flex: auto;
      background: rgba(15, 59, 91, 0.03);
      border-radius: 0 34px 34px 0;
      align-items: flex-start;
      h2{
        font-weight: 700;
        font-size: 16px;
        line-height: 132.5%;
        color: #0F3B5B;
      }

      .viewAll {
        font-weight: 700;
        font-size: 14px;
        line-height: 132.5%;
        text-transform: uppercase;
        color: #0F3B5B;
        border-bottom: 2px solid #474CFB;
        padding-bottom: 2px;
      }

      &-dates {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        row-gap: 15px;

        div {
          width: 100px;
          justify-content: center;
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          row-gap: 8px;

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 132.5%;
            color: rgba(15, 59, 91, 0.6);
          }

          h3 {
            font-weight: 700;
            font-size: 16px;
            line-height: 132.5%;
            color: #0F3B5B;
          }
        }
      }

    }

  }

  .MyFunds {
    padding: 50px 20px;
    flex: auto;
    background: #FFFFFF;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 34px;


    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 132.5%;
      color: #0F3B5B;
    }

    .progress {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      .infoTitle{
        font-family: 'Inter', sans-serif;
        margin-top: 11px;
        text-align: center;
        font-style: italic;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: rgba(53, 53, 61, 0.5);
      }

      .Buttons {
        margin-top: 25px;
        display: flex;
        column-gap: 10px;
        justify-content: space-between;
        align-items: center;

        .button {
          width: 50%;
          padding: 11px 20px;
        }
      }

      .texts {
        display: flex;
        justify-content: space-between;
        margin-bottom: 9px;

        span {
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: -0.02em;
          text-transform: uppercase;
          color: #000000;
        }
      }
    }

    #rrp-fill {
      background: linear-gradient(279.15deg, #474CFB 0%, #8A36C0 100%) !important;
    }

    #rrp-track, #rrp-fill {
      border-radius: 13px !important;
    }
  }
}