.none{
  display: flex;
  position: fixed;
  top: -50%;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(279.15deg, #474CFB 0%, #8A36C0 100%);
  padding: 10px 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 132.5%;
  color: #FFFFFF;
  z-index: 20;
  border-radius: 10px;
  transition: 0.5s;
  animation: showHide 1.9s ease;
}

@keyframes showHide {
  0%{
    top: -50%;
  }
  20%{
    top: 10px;
  }
  70%{
    top: 10px;
  }
  100%{
    top: -50%;
  }
}