@media (max-width: 1470px) {
  .dashboardMain .MyFunds .progress .Buttons {
    flex-direction: column;
    row-gap: 15px;

    .button {
      width: auto !important;
    }
  }
}

@media (max-width: 1024px) {
  .dashboardMain .MyFunds {
    padding: 20px;
  }
  .dashboardMain .Upcoming__total {
    justify-content: flex-start;
    padding: 50px;
  }
  .dashboardMain .MyFunds .progress .Buttons {
    flex-direction: row;
    justify-content: center;
  }
  .dashboardMain .Upcoming {
    width: auto !important;
  }
  .dashboardMain {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .dashboard__tables {
    padding: 30px 20px;
  }
  .dashboard__switches {
    column-gap: 15px;
  }
  .dashboardMain .Upcoming__payments {
    padding: 20px;
  }
  .dashboardMain .Upcoming__total {
    padding: 20px;
    justify-content: flex-start;
  }
}

@media (max-width: 500px) {
  .Upcoming__total{
   row-gap: 15px !important;
  }
  .Upcoming{
    flex-direction: column;
  }
  .Upcoming__payments{
    border-radius: 0 !important;
  }
}

@media (max-width: 450px) {
  .Upcoming__payments {
    text-align: start;

    h2 {
      width: 100%;
    }
  }
  .Upcoming__total {
    text-align: start;
  }
  .dashboardMain .Upcoming__payments-dates {
    gap: 7px !important;
  }
  .wrapper .userName {
    margin-bottom: 20px;
  }
  .dashboardMain {
    row-gap: 20px;
  }
}

@media (max-width: 425px) {
  .dashboard__switches {
    column-gap: 0;
    justify-content: space-between;
  }
  .dashboardMain .MyFunds .progress .Buttons{
    flex-flow: column;
  }
}