.Borrow {
  display: flex;
  column-gap: 55px;

  &__right {
    padding: 52px 25px;
    flex: auto;
    background: #FFFFFF;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 34px;
    display: flex;
    height: 100%;
    flex-direction: column;
    row-gap: 23px;

    div {
      display: flex;
      justify-content: space-between;
    }

    .cash {
      font-weight: 700;
      font-size: 24px;
      line-height: 132.5%;
      color: #0F3B5B;
      display: flex;
      align-items: center;
    }

    .info {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 132.5%;
      color: #0F3B5B;
      column-gap: 10px;
      .question{
        font-size: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 12px !important;
        height: 12px !important;
        border: 1px solid #0F3B5B;
      }
    }

    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 132.5%;
      color: #0F3B5B;

    }
  }

  &__left {
    padding: 50px 40px;
    background: #FFFFFF;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 34px;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &_title {
      font-weight: 700;
      font-size: 16px;
      line-height: 132.5%;
      color: #0F3B5B;
    }

    .minMax {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      font-weight: 600;
      font-size: 12px;
      line-height: 132.5%;
      text-transform: uppercase;
      color: #0F3B5B;


      div {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        row-gap: 3px;
      }

      div:nth-child(2) {
        align-items: flex-end;
      }
    }

    .budget {
      margin-top: 15px;
      font-weight: 600;
      font-size: 16px;
      line-height: 132.5%;
      color: #0F3B5B;
      background: rgba(15, 59, 91, 0.07);
      border-radius: 10px;
      padding: 4px 23px;
      margin-bottom: 15px;
    }

    #rrp-fill {
      background: linear-gradient(279.15deg, #474CFB 0%, #8A36C0 100%) !important;
    }

  }
}