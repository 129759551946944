.login {
  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;
    color: #35353D;
    text-align: center;
  }

  &__form {
    width: 100%;
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &_changePass {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      background: linear-gradient(279.15deg, #474CFB 0%, #8A36C0 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
      text-align: right;
    }

    button {
      margin: 0 auto;
      width: 60%;
    }
  }

  &__alt {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(15, 59, 91, 0.6);

    a {
      color: #474CFB;
    }
  }

  &__haveAcc {
    margin: 20px 0 0;
  }

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;

    &_text {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: rgba(53, 53, 61, 0.5);
    }

    a {
      color: #474CFB;
    }
  }
}

@media (max-width: 525px) {
  .login__form {
    row-gap: 15px;
  }
  .loginLayout {
    width: 100% !important;
    padding: 30px !important;
  }
  .loginSocialMedia {
    margin: 0 !important;
    column-gap: 0 !important;
    justify-content: space-evenly;
  }
  .loginLayout__box {
    width: 100%;
    padding: 30px 22px !important;

    .loginInput {
      width: 100%;
      padding: 11px;
    }
  }
  .loginInput__password {
    svg {
      width: 17px;
      height: 10px;
    }
  }
}