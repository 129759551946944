.HomeUpdates {
  background: linear-gradient(279.15deg, rgba(71, 76, 251, 0.05) 0%, rgba(138, 54, 192, 0.05) 100%);
  padding: 100px 0;

  &__main {
    &_title {
      font-weight: 700;
      font-size: 16px;
      text-align: left;
      text-transform: uppercase;
      color: #5D26EB;
      margin-bottom: 40px;
    }
  }

  .swiperContainer {
    width: 100%;
  }

  .swiper-pagination {
    display: none;
  }
}