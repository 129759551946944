@media (max-width: 1130px) {
  .Borrow__left{
    width: 55%;
  }
}
@media (max-width: 1024px) {
  .Borrow__left{
    width: auto;
    flex: auto;
  }
  .Borrow{
    flex-direction: column;
    row-gap: 30px;
  }
}