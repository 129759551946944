.dashboard {
  background: linear-gradient(279.15deg, #474CFB 0%, #8A36C0 100%) !important;

  &__switches {
    display: flex;
    align-items: center;
    column-gap: 40px;

    a {
      width: 150px;
      height: 42px;
      display: flex;
      align-items: flex-start;
      padding-top: 4px;
      justify-content: center;
      font-weight: 700;
      font-size: 16px;
      overflow: hidden;
      line-height: 24px;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: #FFFFFF;
      position: relative;
      z-index: 0;
      user-select: none;
    }

    a.active span {
      background: linear-gradient(279.15deg, #474CFB 0%, #8A36C0 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    a.active::after {
      left: 0;
      top: 0;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background: #FFFFFF;
      border-radius: 14px 14px 0 0;
      z-index: -1;
    }
  }

  &__tables {
    min-height: calc(100vh - 100px - 42px);
    padding: 70px;
  }
}