.selectLang {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 10px;
  z-index: 10;

  .values {
    cursor: url("../../assets/images/DarkCursor.png"), pointer;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    top: calc(100% + 10px);
    position: absolute;
    transition: 0.3s;
    animation: start 0.3s ease forwards;
    background: #FFFFFF;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 14px;
    padding: 10px 20px 10px 10px;
    user-select: none;

    div {
      display: flex;
      align-items: center;
      column-gap: 10px;
      user-select: none;

      p {
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.02em;
        text-transform: capitalize;
        color: #0F3B5B;
        user-select: none;
      }
    }
  }

  .valueDisabled {
    display: none;
  }
}

.on{
  display: flex !important;
}