.homeFaq {
  padding: 100px 0;


  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 132.5%;
    color: #5D26EB;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__accordion {
    width: 35%;
    border-bottom: 1px solid rgba(15, 59, 91, 0.6);

    * {
      background: transparent !important;
    }

    h2 {
      font-family: 'Poppins', sans-serif;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: #0F3B5B;
    }

    p {
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: #0F3B5B;
    }
  }
}