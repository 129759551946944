.loginLayout {
  background: url("../../assets/images/loginBg.png") center/cover no-repeat;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 50px;
  position: relative;

  &__box {
    background: #FFFFFF;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 26px;
    padding: 50px 44px;
  }
}