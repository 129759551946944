.loginInput {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: rgba(15, 59, 91, 0.6);
  background: linear-gradient(279.15deg, rgba(71, 76, 251, 0.05) 0%, rgba(138, 54, 192, 0.05) 100%);
  border-radius: 14px;
  width: 300px;
  padding: 17px 15px;
  border: 1px solid transparent;
  outline: none;

  &:focus {
    border: 1px solid #5D26EB;
  }

  &__password {
    position: relative;

    input {
      width: 100%;
    }

    svg {
      position: absolute;
      top: 50%;
      right: 5%;
      transform: translate(0, -50%);
    }
  }
}