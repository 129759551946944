@media (max-width: 768px) {
  .footer__main_down-up span {
    text-align: center;
  }
  .HomeBenefits__main_benefitCards {
    flex-direction: column;
    align-items: center;
    row-gap: 27px;
  }
  .HomeBenefits__main_benefitCards-card {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 14px;
    text-align: center;

  }
  .HomeBenefits__main_benefitCards-number {
    width: 83px;
    height: 44.13px;
  }
  .HomeBenefits__main_benefitCards-texts {
    width: 100%;
    margin-left: 0;
  }
  .HomeBenefits__main_benefitCards-card div {
    align-items: center;
    margin-left: 0;
  }
  .darkTheme .HomeBenefits__main_benefitCards-texts h2, .darkTheme .HomeBenefits__main span {
    max-width: 250px;
  }

}

@media (max-width: 640px) {
  .Card {
    width: 100%;
  }
}

@media (max-width: 500px) {

  .HomeUpdates {
    .swiper {
      padding: 0 0 50px;
    }
  }
  .homeIntro__main {
    margin: 30px 0 0;
  }
  .homeIntro__main_right {
    margin: 80px 0 0;

    img {
      width: 70%;
    }
  }
  .header__main_settings {
    column-gap: 15px;
  }
  .header__main {
    a:first-child {
      svg {
        width: 130px;
      }
    }
  }
  .homeIntro__main_left {
    text-align: center;
    align-items: center;

    h2 {
      font-size: 25px;
      line-height: 132.5%;
      width: 87%;
      margin: 0 0 15px;

      br {
        display: unset;
      }
    }

    h3 {
      margin: 0 0 5px;

      span {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  .homeIntro__main_text {
    font-size: 12px;
    line-height: 16px;
  }
  .submitEmail__main_right {
    text-align: center;
    align-items: center;
  }
  .submitEmail__main_right {
    .title {
      font-size: 20px;
      line-height: 132.5%;
      width: 100%;
    }

    .subtitle {
      font-size: 12px;
      line-height: 18px;
    }

    form {
      width: 95%;
      display: block;
      justify-content: unset;

      label {
        justify-content: space-between;
        column-gap: 0;

        input {
          width: 48%;
          font-size: 12px;
          line-height: 18px;
          padding: 8px;
        }

        button {
          width: 48%;
          padding: 8px 0;
          text-align: center;

          span {
            text-align: center;
          }
        }
      }
    }
  }

  .submitEmail__main_right {
    text-align: center;
    align-items: center;

    .title-upper {
      margin: 0;
    }
  }
  .homeFaq__row img {
    width: 100%;
  }
  .submitEmail__main_left img {
    width: 100%;
  }
  .button1, .button2 {
    padding: 8px 30px;

    span {
      font-size: 12px;
      line-height: 18px;
    }
  }
  .HomeBenefits__main_title {
    font-size: 20px;
    line-height: 132.5%;
    width: 90%;
    margin: 0 0 15px;
  }
  .HomeBenefits__main_subtitle {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin: 0 0 40px;
  }
  .HomeBenefits__main_benefitCards-number {
    font-size: 31.5545px;
    line-height: 47px;
    padding: 0 30px;
  }
  .HomeBenefits__main_benefitCards-texts {
    h2 {
      font-size: 15px;
      line-height: 125.5%;
    }

    span {
      font-size: 12px;
      line-height: 18px;
      width: 60%;
    }
  }
  .HomeBenefits__main_benefitCards-texts {
    row-gap: 10px;
  }
  .HomeBenefits {
    padding: 60px 15px;
  }
  .homeFaq {
    padding: 60px 15px;

    &__row {
      margin: 35px 0 0;

      img {
        width: 70%;
      }
    }

    &__accordion {
      h2 {
        font-size: 15px;
        line-height: 22px;
      }

      p {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .HomeUpdates {
    padding: 60px 15px;
  }
  .Card {
    h2 {
      font-size: 14px;
      line-height: 125.5%;
      margin: 20px 0 15px;
    }

    .desc {
      font-size: 12px;
      line-height: 18px;
      height: auto;
    }

    &__down {
      margin: 10px 0 0;

      &_title {
        font-size: 10px;
        line-height: 15px;
      }

      button {
        column-gap: 15px;
      }
    }
  }
  .homeInvestors {
    padding: 60px 15px;

    &__subtitle {
      font-size: 20px;
      line-height: 132.5%;
      margin: 30px auto 0;
      width: 60%;
    }

    &__text {
      font-size: 12px;
      line-height: 132.5%;
      margin: 10px auto 15px;
      width: 60%;
    }

    &__button {
      padding: 15px;
      font-size: 9.70667px;
      line-height: 15px;
      column-gap: 10px;

      svg {
        width: 23px;
        height: 16px;
      }
    }
  }
  .footer {
    padding: 60px 15px;

    &__main {
      row-gap: 40px;

      &_up {
        &-left {
          row-gap: 0;

          a {
            svg {
              width: 170px;
              height: 42px;
            }

            &:first-child {
              margin: 0 0 30px;
            }

            &:nth-child(3) {
              margin: 15px 0;
            }
          }
        }

        &-right {
          svg {
            height: 41px;
            width: 15px;
          }
        }
      }

      &_down {
        &-up {
          align-items: flex-start;
        }

        .terms {
          margin: 0 auto;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          column-gap: 40px;
          justify-content: center;

          span {
            font-size: 12px;
            line-height: 18px;

            &:nth-child(1) {
              order: 3;
            }
          }
        }
      }
    }
  }
  .footer__main_down-down span {
    font-size: 10px;
    line-height: 15px;
    text-align: center;
  }
  .header__main_settings {
    column-gap: 8px;
  }

}