.homeInvestors {
  padding: 100px 0;

  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 132.5%;
    text-transform: uppercase;
    color: #5D26EB;
  }

  &__subtitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 132.5%;
    color: #0F3B5B;
    text-align: center;
    margin: 40px 0 0;
  }

  &__text {
    font-size: 24px;
    line-height: 132.5%;
    text-align: center;
    color: #0F3B5B;
    margin: 15px 0 50px;
  }
  &__buttonParent{
    display: flex;
    justify-content: center;
  }
  &__button{
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: rgba(15, 59, 91, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 31px;
    column-gap: 18px;
    border: 1px solid rgba(15, 59, 91, 0.6);
    border-radius: 30px;
  }
}