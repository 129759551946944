.switch {
  height: 30px;
  width: 60px;
  position: relative;
  border-radius: 90px;
  transition: 0.3s;


  &__circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 1px 2px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-disabled {
      left: 10px;
      transform: translate(-15%, -50%);
      transition: 0.3s;
    }

    &-active {
      left: 50px;
      transform: translate(-85%, -50%);
      transition: 0.3s;
    }
  }
}