.HomeBenefits {
  padding: 120px 0;
  background: linear-gradient(279.15deg, rgba(71, 76, 251, 0.05) 0%, rgba(138, 54, 192, 0.05) 100%);

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_title {
      max-width: 610px;
      font-weight: 700;
      font-size: 24px;
      line-height: 132.5%;
      text-align: center;
      color: #0F3B5B;
      margin-bottom: 9px;
    }

    &_subtitle {
      margin-bottom: 80px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.02em;
      color: rgba(31, 16, 68, 0.6);
    }

    &_benefitCards {
      display: flex;
      width: 100%;
      justify-content: space-between;

      &-card {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 33.333%;

        div {
          margin-left: 28px;
        }
      }

      &-texts {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        width: 68.8%;

        h2 {
          font-weight: 600;
          font-size: 20px;
          line-height: 25px;
          letter-spacing: -0.02em;
          color: #0F3B5B;
        }

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.02em;
          color: #0F3B5B;
        }
      }

      &-number {
        width: 60px;
        height: 60px;
        background: linear-gradient(279.15deg, #474CFB 0%, #8A36C0 100%);
        border-radius: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 42.9px;
        line-height: 64px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
      }


    }
  }
}