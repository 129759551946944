.Burger {
  width: 20px;
  height: 10px;
  position: relative;
  transition: 0.3s;

  &__up {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 2px;
    background: #0F3B5B;
    border-radius: 10px;
    transition: 0.3s;
  }

  &__down {
    bottom: 0;
    position: absolute;
    width: 100%;
    left: 0;
    height: 2px;
    background: #0F3B5B;
    border-radius: 10px;
    transition: 0.3s;
  }
}

.active {
  .Burger__up {
    top: 50%;
    transform: rotate(45deg) translateY(-50%);
  }

  .Burger__down {
    transform: rotate(-45deg) translateY(50%);
    bottom: 50%;
  }
}


.blur {
  z-index: 1;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(15, 59, 91, 0.5);
  left: 0;
  top: 100%;
  animation: 0.3s ease showBlur;
}

.hidden {
  max-height: 100vh;
  overflow: hidden;
}

.BurgerNavbar {
  z-index: 2;
  display: flex;
  position: absolute;
  right: 0;
  width: 300px;
  row-gap: 30px;
  top: 100%;
  padding: 25px 30px;
  flex-direction: column;
  background: #FFFFFF;

  .link {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    color: #0F3B5B;
  }

  &__buttons {
    padding: 32px 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 0.766667px solid rgba(15, 59, 91, 0.6);
    border-top: 0.766667px solid rgba(15, 59, 91, 0.6);

    a {
      font-weight: 400;
      font-size: 16.2911px;
      line-height: 24px;
      letter-spacing: -0.02em;
    }

    a:nth-child(2) {
      padding: 5px 12px;
      background: #5D26EB;
      border-radius: 12.5316px;
      color: #FFFFFF;
    }
  }
  .SignOutBurger{
    padding: 6px 10px;
    background: #5D26EB;
    border-radius: 10px;
    color: #FFFFFF;
    border: none;
  }
}

.disableBurger {
  display: none;
}


@keyframes showBlur {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}