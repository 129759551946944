.footer {
  padding: 100px 0;
  background: linear-gradient(279.15deg, #474CFB 0%, #8A36C0 100%);

  &__main {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    row-gap: 100px;

    &_up {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-left {
        display: flex;
        column-gap: 60px;
        align-items: center;

        a {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: -0.02em;
          text-transform: uppercase;
          color: #FFFFFF;
        }

        svg {
          margin-right: 100px;
        }
      }


    }

    &_down {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      &-up {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.02em;
          color: #FFFFFF;
        }

        div {
          column-gap: 60px;
          display: flex;
          align-items: center;
        }

        div:nth-child(2) {
          column-gap: 30px;
        }
      }

      &-down {
        display: flex;
        align-items: center;

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: -0.02em;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  .heart {
    color: #ff2f03;
  }
}