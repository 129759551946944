.dashboardHistory {
  &__box {
    background: #FFFFFF;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 34px;
    padding: 50px 50px 35px;

    &_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px 20px;
      border-bottom: 1px solid rgba(15, 59, 91, 0.15);

      &_item {
        width: calc(100% / 4);
        font-weight: 700;
        font-size: 14px;
        line-height: 132.5%;
        text-transform: uppercase;
        color: rgba(15, 59, 91, 0.6);

        &:nth-child(2) {
          text-align: center;
        }

        &:nth-child(3) {
          text-align: center;
        }

        &:nth-child(4) {
          text-align: right;
        }
      }
    }

    &_body {
      &_row {
        margin: 15px 0 0;
        padding: 13px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;

        &:nth-child(even) {
          background: linear-gradient(279.15deg, rgba(71, 76, 251, 0.05) 0%, rgba(138, 54, 192, 0.05) 100%);
        }

        &-item {
          width: calc(100% / 4);

          &-1 {
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            p {
              font-weight: 700;
              font-size: 24px;
              line-height: 132.5%;
              color: #0F3B5B;
            }

            span {
              font-size: 12px;
              line-height: 132.5%;
              color: rgba(15, 59, 91, 0.6);
            }
          }

          &-2 {
            text-align: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 132.5%;
            color: #0F3B5B;
          }

          &-3 {
            text-align: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 132.5%;
            color: #0F3B5B;
          }

          &-4 {
            display: flex;
            justify-content: flex-end;

            button {
              background: linear-gradient(279.15deg, #EAE7FC 0%, #F1E6F7 100%);
              border-radius: 11px;
              font-weight: 500;
              font-size: 14px;
              line-height: 132.5%;
              color: #0F3B5B;
              padding: 5px 20px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}