.News {
  padding-top: 100px;
  .container {
    display: flex;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #0F3B5B;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    white-space: pre-wrap;
  }

  &__title {
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 24px;
    line-height: 125.5%;
    letter-spacing: -0.02em;
    color: #0F3B5B;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 52px;

    &_infoCard {
      h2 {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: rgba(15, 59, 91, 0.6);
      }

      span {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #0F3B5B;
      }
    }
  }

  &__main {
    padding-right: 200px;
    display: flex;
    flex-direction: column;
    width: 70%;
  }

  &__aside {
    width: 30%;
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    padding-bottom: 70px;
  }
}


@media (max-width: 1440px) {
  .News {
    padding: 100px 15px;

    .Card {
      width: 100%;
    }

    &__main {
      padding-right: 50px;
    }
  }
}

@media (max-width: 1024px) {
  .News {
    &__main {
      width: 60%;
    }

    &__aside {
      width: 40%;
    }
  }
}

@media (max-width: 768px) {
  .News {
    padding-top: 30px;
    &__main {
      width: 100%;
      padding-right: 0 !important;
    }

    &__aside {
      width: 100%;
      margin-top: 30px;
    }

    .container {
      flex-direction: column;
    }
  }
}