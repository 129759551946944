.Card {
  display: flex;
  user-select: none;
  flex-direction: column;
  width: 400px;
  position: relative;
  top: 0;
  transition: 0.3s;

  &__imageBlock {
    width: 100%;
    background: linear-gradient(279.15deg, rgba(71, 76, 251, 0.1) 0%, rgba(138, 54, 192, 0.1) 100%);
    border-radius: 28px;
    height: 199px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.02em;
    color: #0F3B5B;
    width: 100%;
    overflow: hidden;
    height: 25px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #0F3B5B;
    height: 72px;
    overflow: hidden;
  }

  &__down {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;

    &_title {
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 20px;
      color: rgba(15, 59, 91, 0.6);
    }

  }
}
