.joinModal {
  background: url("../../assets/images/loginBg.png") center/cover no-repeat;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 50px;
  position: relative;

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
    color: #35353D;
    text-align: center;
  }

  &__subtitle {
    text-align: center;
    font-size: 16px;
    line-height: 150%;
    color: #0F3B5B;
    margin: 20px 0 100px;
  }

  &__text {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #35353D;
    margin: 0 0 30px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__card {
    width: 25%;

    &_img {
      background: linear-gradient(279.15deg, #EAE7FC 0%, #F1E6F7 100%);
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
      border-radius: 28px;
      display: flex;
      justify-content: center;
      img {
        height: 130px;
      }
    }

    &_content {
      padding: 20px 5px 0;
    }

    &_title {
      font-weight: 600;
      font-size: 20px;
      line-height: 125%;
      letter-spacing: -0.02em;
      color: #0F3B5B;
      margin: 0 0 30px;
    }

    &_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &_date {
      font-weight: 300;
      font-size: 13px;
      line-height: 150%;
      color: rgba(15, 59, 91, 0.6);
    }
  }
}

@media (max-width: 1350px) {
  .joinModal {
    .container {
      max-width: 1200px;
    }

    &__card {
      width: 32%;
    }
  }
}

@media (max-width: 1200px) {
  .joinModal {
    .container {
      max-width: 95%;
    }

    &__text {
      margin: 0 0 70px;
    }

    &__row {
      flex-direction: column;
      align-items: center;
      row-gap: 50px;
    }

    &__card {
      width: 40%;
    }
  }
}

@media (max-width: 996px) {
  .joinModal {

    &__card {
      width: 60%;
    }
  }
}

@media (max-width: 660px) {
  .joinModal {

    &__card {
      width: 80%;
    }
  }
}

@media (max-width: 500px) {
  .joinModal {
    &__title {
      font-size: 24px;
    }

    &__subtitle {
      margin: 20px 0 60px;
      font-size: 14px;
    }

    &__text {
      margin: 0 0 40px;
      font-size: 16px;
    }

    &__card {
      width: 70%;

      &_title {
        margin: 0 0 15px;
        font-size: 16px;
      }
    }
  }
}