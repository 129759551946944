.submitEmail {
  padding: 42px 0;

  &__button {
    margin: 24px 0 0;
    width: 50%;
  }

  &__main {
    display: flex;
    justify-content: space-between;

    &_left {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 150px;
      width: 50%;
    }

    &_right {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .title {
        max-width: 500px;
        font-weight: 700;
        font-size: 24px;
        color: #0F3B5B;

        &-upper {
          text-transform: uppercase;
        }

        span {
          font-weight: 700;
          font-size: 24px;
          line-height: 132.5%;
          background: linear-gradient(279.15deg, #474CFB 0%, #8A36C0 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
        }
      }

      .subtitle {
        margin-top: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.02em;
        color: rgba(31, 16, 68, 0.6)
      }

      form {
        margin-top: 24px;

        label {
          display: flex;
          column-gap: 40px;
          align-items: center;
        }

        input {
          font-size: 16px;
          background: none;
          min-width: 400px;
          padding: 12px 22px;
          border: 1px solid rgba(15, 59, 91, 0.6);
          border-radius: 3px;
          outline: none;
        }

        input::placeholder {
          font-style: normal;
          font-weight: 300;
          font-size: 13px;
          line-height: 20px;
          color: rgba(15, 59, 91, 0.6);
        }
      }

      width: 50%;
    }

  }
}