.button {
  padding: 11px 46px;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  transition: 0.3s;
}

.button:hover {
  transition: 0.3s;

  .svg {
    transition: 0.3s;
    fill: #10052D !important;

    path {
      transition: 0.3s;
      fill: #10052D !important;
    }
  }
}

.button1 {
  transition: 0.3s;
  border: 1px solid #5D26EB;
  background: linear-gradient(279.15deg, #ffffff 0%, #ffffff 100%);

  span {
    transition: 0.3s;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    background: linear-gradient(279.15deg, #474CFB 0%, #8A36C0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.button1:hover {
  transition: 0.3s;
  background: linear-gradient(279.15deg, #474CFB 0%, #8A36C0 100%);

  span {
    transition: 0.3s;
    background: linear-gradient(279.15deg, #ffffff 0%, #ffffff 0%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.button2 {
  transition: 0.3s;
  background: linear-gradient(279.15deg, #474CFB 0%, #8A36C0 100%);
  border: 1px solid #5D26EB;

  span {
    transition: 0.3s;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-transform: uppercase;

    background: linear-gradient(279.15deg, #ffffff 0%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.button2:hover {
  background: linear-gradient(279.15deg, #ffffff 0%, #ffffff 100%);
  border: 1px solid #5D26EB;
  transition: 0.3s;

  span {
    transition: 0.3s;
    background: linear-gradient(279.15deg, #474CFB 0%, #8A36C0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.urlButton {
  padding: 11px 22px;
  border-radius: 16px;
  transition: 0.3s;
}