.homeIntro {
  padding: 10px 0;

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button {
      margin-top: 25px;
    }

    &_left {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h3 {
        span {
          font-weight: 700;
          font-size: 15px;
          line-height: 22px;
          text-transform: uppercase;
          background: linear-gradient(279.15deg, #474CFB 0%, #8A36C0 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
          margin-bottom: 7px;
        }
      }

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 132.5%;
        color: #0F3B5B;
        margin-bottom: 10px;

        br {
          display: none;
        }

        span {
          color: #0F3B5B60;
        }
      }

      &_text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: rgba(31, 16, 68, 0.6);
        margin-bottom: 26px;
      }
    }

    &_right {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      img {
        width: 70%;
      }
    }
  }
}

.animation-title {
  margin: 0 0 0 7px;
  border-right: .15em solid orange;
  white-space: nowrap;
  animation: blink 1s infinite;
}

@keyframes blink {
  from {
    border-color: transparent
  }
  to {
    border-color: orange;
  }
}