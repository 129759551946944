.darkTheme {
  background: #10052D;
  cursor: url('../../../assets/images/whiteCursor.png'), pointer;

  .button2 {
    cursor: url('../../../assets/images/whiteCursor.png'), pointer !important;
  }

  .button1 {
    cursor: url('../../../assets/images/BlueCursor.png'), pointer;
  }

  .header {
    a, button {
      cursor: url('../../../assets/images/whiteCursor.png'), pointer
    }

    * {
      color: #DAF5FD;
    }
  }

  .homeIntro {
    background: #10052D;


    .button1 {
      cursor: url('../../../assets/images/whiteCursor.png'), pointer;
    }

    &__main {
      &_left {
        p {
          color: rgba(218, 245, 253, 0.6);
        }

        h2 {
          color: #FFFFFF;

          span {
            color: #9B9B9B;
          }
        }
      }
    }
  }

  .submitEmail {
    background: #10052D;

    &__main {
      &_right {
        .title {
          color: #FFFFFF;
        }

        form {
          input {
            color: rgba(218, 245, 253, 0.6);
          }

          input::placeholder {
            color: rgba(218, 245, 253, 0.6);
          }
        }

        .subtitle {
          color: rgba(218, 245, 253, 0.6);
        }
      }
    }
  }

  .HomeBenefits {
    background: linear-gradient(279.15deg, rgba(71, 76, 251, 0.2) 0%, rgba(138, 54, 192, 0.2) 100%);

    &__main {
      &_benefitCards-texts h2, span {
        color: #FFFFFF;
      }

      &_title {
        color: #FFFFFF;
      }

      &_subtitle {
        color: rgba(218, 245, 253, 0.6);
      }


    }
  }

  .HomeUpdates {
    background: none;
  }

  .homeInvestors {
    background: linear-gradient(279.15deg, rgba(71, 165, 251, 0.2) 0%, rgba(71, 165, 251, 0.05) 0%, rgba(71, 165, 251, 0.2) 0.01%, rgba(59, 67, 255, 0.2) 100%);

    &__subtitle {
      color: #FFFFFF;
    }

    &__text {
      color: #FFFFFF;
    }

    &__button {
      border: 1px solid rgba(218, 245, 253, 0.6);
      color: #DAF5FD;
      cursor: url('../../../assets/images/whiteCursor.png'), pointer;
    }
  }

  .homeFaq__accordion {
    * {
      background: transparent !important;
    }

    h2 {
      color: #FFFFFF;
    }

    p {
      color: #FFFFFF;
    }
  }

  .Card {
    h2, .desc {
      color: #FFFFFF;
    }

    &__down {
      &_title {
        color: rgba(218, 245, 253, 0.6);
      }
    }
  }

  .footer {
    background: #10052D;
  }

  .BurgerNavbar {
    background: #10052D;
  }

  .News {
    &__title {
      color: #FFFFFF;
    }

    .News__info_infoCard h2 {
      color: rgba(218, 245, 253, 0.6);
    }

    .News__info_infoCard span {
      color: #FFFFFF;
    }

    .News__description {
      color: rgba(218, 245, 253, 0.6);
    }
  }

  .joinModal {
    &__title, &__card_title {
      color: #FFFFFF;
    }

    &__subtitle, &__text {
      color: rgba(218, 245, 253, 0.6);
    }

    &__card_date {
      color: rgba(218, 245, 253, 0.6);
    }
  }

  .dashboard {
    background: #10052d !important;

    &__switches a.active::after {
      background: linear-gradient(279.15deg, rgba(71, 76, 251, 0.2) 0%, rgba(138, 54, 192, 0.2) 100%);
    }
  }

  .dashboard__tables {
    background: linear-gradient(279.15deg, rgba(71, 76, 251, 0.2) 0%, rgba(138, 54, 192, 0.2) 100%);
    .userName {
      color: white;
    }
  }
}