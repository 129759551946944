@media (max-width: 930px) {
  .HomeUpdates {
    .swiper {
      padding: 0 0 70px;
    }
    .swiper-pagination {
      display: block;
    }

    .swiper-pagination-bullet {
      background: linear-gradient(279.15deg, rgba(71, 76, 251, 0.5) 50%, rgba(161, 58, 219, 0.1) 100%);
    }

    .swiper-pagination-bullet-active {
      background: linear-gradient(279.15deg, #474CFB 0%, #8A36C0 100%);
    }
  }
  .homeIntro {
    &__main {
      flex-flow: row wrap;
      margin-top: 50px;

      &_right {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin: 40px 0;

        img {
          width: 50%;
        }
      }

      &_left {
        width: 100%;

      }
    }
  }
  .submitEmail {
    &__main {
      flex-flow: column-reverse wrap;

      &_right {
        width: 100%;
      }

      &_left {
        justify-content: center;
        width: 100%;
      }
    }
  }
  .homeFaq__row {
    flex-direction: column-reverse;

    img {
      margin-top: 30px;
    }
  }
  .homeFaq__accordion {
    width: 100%;
  }
  .submitEmail__main_right {
  }
  .submitEmail__main_right .title {

    text-align: center;
    width: 60%;
    margin: 0 auto;
  }
  .submitEmail__main_right .title-upper {
    text-align: center;
    max-width: 100%;
    margin: 0 auto 10px;
  }
  .submitEmail__main_right .subtitle {
    text-align: center;
    margin: 15px 0 0;
  }
  .submitEmail__main_right form {
    display: flex;
    justify-content: center;
  }
  .submitEmail__button {
    display: flex;
    justify-content: center;
    margin: 24px auto 0;
  }
}

@media (max-width: 820px) {
  .footer__main_up {
    align-items: flex-start;
  }
  .footer__main_up-left {
    flex-direction: column;
    row-gap: 30px;
    align-items: flex-start;
  }
  .footer__main_down-up .terms {
    flex-direction: column-reverse;
    row-gap: 7px;
  }
  .footer__main_down-up div:nth-child(2) {
    margin-bottom: 30px;
  }
  .footer__main_down-up {
    flex-direction: column-reverse;
  }
}