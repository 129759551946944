@media (max-width: 1200px) {
  .header {
    &__main {
      &_navbar {
        display: none;
      }
    }

    .selectLang {
      display: none;
    }

    .BurgerMenu {
      display: flex;
    }

    .switch {
      display: none;
    }

    .SignUp {
      display: none;
    }

    .Login {
      display: none;
    }
  }
}