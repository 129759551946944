.header {
  padding: 26px 0;
  position: relative;

  .BurgerMenu {
    display: none;
  }

  a, button {
    cursor: url("../../assets/images/DarkCursor.png"), pointer;
  }

  * {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #0F3B5B;
  }


  &__main {
    display: flex;
    justify-content: space-between;

    &_right {
      display: flex;
      align-items: center;
      column-gap: 150px;
    }

    &_navbar {
      display: flex;
      align-items: center;
      column-gap: 60px;
    }

    &_settings {
      display: flex;
      align-items: center;
      column-gap: 40px;

      .Login {
        padding: 6px 10px;
        background: transparent;
        border-radius: 10px;
        border: none;
      }

      .SignUp {
        padding: 6px 10px;
        background: #5D26EB;
        border-radius: 10px;
        color: #FFFFFF;
        border: none;
      }


      .icon {
        width: 23px;
        height: 23px;
        user-select: none;
      }
    }
  }
}

.headerBlue {
  background: linear-gradient(279.15deg, #474CFB 0%, #8A36C0 100%) !important;

  .SignUp {
    background: #FFFFFF;
    color: #0F3B5B !important;
  }

  .header__main_navbar {
    a {
      color: #FFFFFF;
    }
  }
}

@keyframes start {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
